import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import html2pdf from 'html2pdf.js';

const FormPDFGenerator = () => {

    useEffect(() => {
        assignNumbers();
    }, []);

    function assignNumbers() {
        // Here, inputs seems to be a scalar value
        var inputs = document.querySelectorAll(".clause-count");
        inputs.forEach(function (input, index) {
            input.innerHTML = 'Clause ' + (index + 1);

        });
    }
    const [serial, setSerial] = useState(Array.from({ length: 35 }, (_, index) => index + 1));

    const [step, setStep] = useState(1);
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [fathername, setFathername] = useState('');
    const [husbandname, setHusbandname] = useState('');
    const [address, setAddress] = useState('');
    const [gender, setGender] = useState('male');
    const [married, setMarried] = useState('no');
    const [email, setEmail] = useState('');
    const [showLocation, setShowLocation] = useState(true);
    const [answer, setanswer] = useState('no');

    const [selectOther, setSelectOther] = useState('Partnership');
    const [entityFor_Franchisee, setEntityFor_Franchisee] = useState('companyFor_Franchisee');
    const [entity, setEntity] = useState('company');
    const [signName, setSignName] = useState('');
    const [designation, setDesignation] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [country, setCountry] = useState('');

    const [name_of_Franchisee, setName_of_Franchisee] = useState('');
    const [gender_of_Franchisee, setGender_of_Franchisee] = useState('male');
    const [married_of_Franchisee, setMarried_of_Franchisee] = useState('no');
    const [Franchisee_husband, setFranchisee_husband] = useState('');
    const [Franchisee_father, setFranchisee_father] = useState('');
    const [address_of_Franchisee, setAddress_of_Franchisee] = useState('');
    const [email_of_Franchisee, setEmail_of_Franchisee] = useState('');
    const [country_of_Franchisee, setCountry_of_Franchisee] = useState('');
    const [signName_of_Franchisee, setSignName_of_Franchisee] = useState('');
    const [designation_of_Franchisee, setDesignation_of_Franchisee] = useState('');
    const [franchisorBusiness, setFranchisorBusiness] = useState('');
    const [brandName, setBrandName] = useState('');
    const [fullAddress, setFullAddress] = useState('');
    const [selectOther_Franchisee, setSelectOther_Franchisee] = useState('Partnership');
    const [exclusive, setExclusive] = useState('Exclusive');
    const [showLocationSelect, setshowLocationSelect] = useState('')
    const [showterritorySelect, setshowterritorySelect] = useState('')
    const [sellProducts, setsellProducts] = useState('no');
    const [sellProducts1, setsellProducts1] = useState('no');
    const [agreed, setagreed] = useState('no');
    const [cause3, setCause3] = useState('')
    const [cause3text, setcause3text] = useState('')
    const [fees, setFees] = useState('');
    const [feesValue, setfeesValue] = useState('yes');
    const [feesAgree, setfeesAgree] = useState('');
    const [periodic, setperiodic] = useState('');
    const [penalties, setpenalties] = useState('');
    const [gross, setGross] = useState('weekly');
    const [royalty, setroyalty] = useState('yes');
    const [agreementSignDate, setagreementSignDate] = useState('');
    const [Agreement_Franchisee, setAgreement_Franchisee] = useState('');
    const [procedures, setprocedures] = useState('yes');
    const [expirationDate, setexpirationDate] = useState('anniversary of opening');
    const [anniversary, setanniversary] = useState('1st');
    const [expireDate, setexpireDate] = useState('');
    const [Agreement_Expire, setAgreement_Expire] = useState('')
    const [timePeriod, setTimePeriod] = useState('');
    const [approveRenewal, setApproveRenewal] = useState('yes');
    const [approveRenewaltext, setApproveRenewaltext] = useState('');
    const [training, setTraining] = useState('yes');
    const [physical, setphysical] = useState('yes');
    const [investigation, setinvestigation] = useState('yes');
    const [preOpening, setpreOpening] = useState('yes');
    const [ongoing, setongoing] = useState('yes');
    const [obligations, setobligations] = useState('');
    const [properietary, setproperietary] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [restrained, setrestrained] = useState('');
    const [restrainedAddress, setrestrainedAddress] = useState('');
    const [premises, setpremises] = useState('yes');
    const [premisesDay, setPremisesDay] = useState('');
    const [insurance, setinsurance] = useState('');
    const [way, setWay] = useState('twoWay')
    const [JointVenture, setJointVenture] = useState('yes')
    const [JointVentureText, setJointVentureText] = useState('')
    const [JointVentureDate, setJointVentureDate] = useState('')
    const [isCheckedMechanism, setisCheckedMechanism] = useState(false);
    const [arbitration, setarbitration] = useState('');
    const [courts, setcourts] = useState('');
    const [lawyers, setlawyers] = useState('yes')
    const [assign, setAssign] = useState('yes')
    const [isSolicitation, setIsSolicitation] = useState(false);
    const [client, setClient] = useState('');
    const [Territory, setTerritory] = useState('Territory')
    const [sold, setSold] = useState('')

    const formattedDate = (date) => {
        return moment(date).format('MM/DD/YYYY');
    };
    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }
    const handleNext = () => {
        if (step < 23) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleAswerChange = (e) => {
        setanswer(e.target.value)
        scrollToElement('other')
    }
    const handleEntityChange = (e) => {
        setEntity(e.target.value);
        scrollToElement('date')
    };
    const handle_entityFor_FranchiseeChange = (e) => {
        setEntityFor_Franchisee(e.target.value);
    };
    const handle_genderChange = (event) => {
        setGender(event.target.value);
        scrollToElement('individual')
       
        
    };
    const handle_marriedChange = (e) => {
        setMarried(e.target.value);
        scrollToElement('individual')
    }

    const handle_selectOther = (e) => {
        setSelectOther(e.target.value)
        scrollToElement('other')
    }
    const handle_selectOther_Franchisee = (e) => {
        setSelectOther_Franchisee(e.target.value)
        scrollToElement('individual')
    }
    const handle_geneder_Franchisee_Change = (e) => {
        setGender_of_Franchisee(e.target.value)
        scrollToElement('individual')
    }
    const handle_married_Franchisee_Change = (e) => {
        setMarried_of_Franchisee(e.target.value)
        scrollToElement('individual')
    }
    const handlesellProductsClick = (e) => {
        setsellProducts(e.target.value)
        scrollToElement('product')
    }
    const handlesellProducts1Click = (e) => {
        setsellProducts1(e.target.value)
        scrollToElement('product')
    }
    const handleAgreedclick = (e) => {
        setagreed(e.target.value)
        scrollToElement('agree')
    }

    const handle_exclusive_Change = (event) => {
        setExclusive(event.target.value);
        scrollToElement('product')
    };
    const handleTerritoryChange = (e) => {
        setTerritory(e.target.value)
        scrollToElement('clause1')

    }

    const handleClause3chnage = (e) => {
        setCause3(e.target.value)
        scrollToElement('agree')
        assignNumbers();
    }
    const handleClausePeregraphChange = () => {
        const Element = document.getElementById('cause3peragraph')
        if (Element) {
            Element.scrollIntoView({ behavior: 'smooth' });
            Element.style.display = 'block';
            Element.style.backgroundColor = 'red'
        }
    }
    const handle_No_ClausePeregraphChange = () => {
        const Element = document.getElementById('cause3peragraph')
        if (Element) {
            Element.scrollIntoView({ behavior: 'smooth' });
            Element.style.display = 'none';
        }
    }
    const handlefeesValueChange = (e) => {
        setfeesValue(e.target.value)
        scrollToElement('fees')
    }
    const handleGrossChange = (e) => {
        setGross(e.target.value)
        scrollToElement('fees')
    }
    const handleroyaltyChange = (e) => {
        setroyalty(e.target.value)
        scrollToElement('reserved')
        
    }
    const handleproceduresChange = (e) => {
        setprocedures(e.target.value)
        scrollToElement('agreementSign')
    }
    const handleExpirationChange = (e) => {
        setexpirationDate(e.target.value)
        scrollToElement('agreementDate')
    }
    const handleAnniversaryChange = (e) => {
        setanniversary(e.target.value)
    }
    const handle_approveRenewal_Change = (e) => {
        setApproveRenewal(e.target.value)
    }
    const handle_training_Change = (e) => {
        setTraining(e.target.value)
        scrollToElement('obligation')
        
    }
    const handle_physical_Change = (e) => {
        setphysical(e.target.value)
        scrollToElement('obligation')
    }
    const handle_investigation_Change = (e) => {
        setinvestigation(e.target.value)
        scrollToElement('obligation')
    }
    const handle_preOpening_Change = (e) => {
        setpreOpening(e.target.value)
        scrollToElement('obligation')
    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        scrollToElement('non-competition')
        

    };
    const handlepremisesChange = (e) => {
        setpremises(e.target.value)
        scrollToElement('non-competition')
    }
    const handleWayChange = (e) => {
        setWay(e.target.value)
        scrollToElement('way')
    }
    const handleJointVentureChange = (e) => {
        setJointVenture(e.target.value)
    }
    const handleCheckedMechanismChange = () => {
        setisCheckedMechanism(!isCheckedMechanism);
    };
    const handlelawyersChange = (e) => {
        setlawyers(e.target.value)
    }
    const handleAssignChange = (e) => {
        setAssign(e.target.value)
    }
    const handleSolicitationChange = () => {
        setIsSolicitation(!isSolicitation);
        assignNumbers();
    };
    const generatePDF = () => {
        const element = document.getElementById('content');
        const opt = {
            margin: 0.5,
            filename: 'Franchise_Agreement.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: 'avoid-all' },
            onbeforestart: function (element, renderer) {
                renderer.pdf.setFontSize(10);
                renderer.pdf.setTextColor(150);
            }
        };

        const css = `
            <style>
                #content {
                    text-align: center;
                }
            </style>
        `;
        const htmlWithStyle = css + element.innerHTML;

        html2pdf().from(htmlWithStyle).set(opt).save();
    };


    return (
        <>
            <div className='container-fluid pt-4'>
                <div className='col-3 question-side pb-5 pt-5 bg-light'>

                    {step === 1 && (
                        <div className='divOne'>
                            <p>What is the date on which the agreement will be signed?</p>
                            <input type="date" value={date} onChange={(e) => {setDate(e.target.value);}} />

                            <p className='mt-5'>What is the nature of entity of the Franchisor (i.e. the entity who is agreeing to let someone else use their business model)?</p>
                            <select name="entity" id="entity" value={entity} onChange={handleEntityChange}>
                                <option value="company">company</option>
                                <option value="individual">individual</option>
                                <option value="other">other</option>
                            </select>
                        </div>
                    )}
                    {step === 2 && (

                        <div>
                            {entity === 'individual' && (
                                <div>
                                    <p>What is the name of the Franchisor?</p>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='E.g.rohit sharma' />
                                    <p>What is the gender of the Franchisor?</p>
                                    <input type="radio" id="male" name="gender" value="male" checked={gender === 'male'} onChange={handle_genderChange} />
                                    <label>Male</label><br />
                                    <input type="radio" id="female" name="gender" value="female" checked={gender === 'female'} onChange={handle_genderChange} />
                                    <label >Female</label><br />
                                    {gender === 'female' && (
                                        <div>
                                            <p>Is the Franchisor married?</p>
                                            <input type="radio" id="yes" name="married" value="yes" checked={married === 'yes'} onChange={handle_marriedChange} />
                                            <label>Yes</label><br />
                                            <input type="radio" id="no" name="married" value="no" checked={married === 'no'} onChange={handle_marriedChange} />
                                            <label >No</label><br />
                                            {married === 'no' && (
                                                <div>
                                                    <p>What is the father's name of the Franchisor?</p>
                                                    <input type="text" value={fathername} onChange={(e) => setFathername(e.target.value)} placeholder='E.g.rohit sharma' />
                                                </div>
                                            )}
                                            {married === 'yes' && (
                                                <div>
                                                    <p>  What is the husband's name of the Franchisor?</p>
                                                    <input type="text" value={husbandname} onChange={(e) => setHusbandname(e.target.value)} placeholder='E.g.rohit sharma' />
                                                </div>
                                            )}
                                        </div>

                                    )}
                                    {gender === 'male' && (
                                        <div>
                                            <p>What is the father's name of the Franchisor?</p>
                                            <input type="text" value={fathername} onChange={(e) => setFathername(e.target.value)} placeholder='E.g.rohit sharma' />
                                        </div>
                                    )}
                                </div>

                            )}

                            {entity === 'other' && (
                                <div>
                                    <p>What is the name of the Franchisor?</p>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='E.g.rohit sharma' />

                                    <p>What is the nature of the entity which is the Franchisor (society, partnership etc.)?</p>
                                    <select name="entity" id="selectOther" value={selectOther} onChange={handle_selectOther}>
                                        <option value="partnership">Partnership</option>
                                        <option value="Society">Society</option>
                                        <option value="Trust">Trust</option>
                                        <option value="Limited liability Partnership">Limited liability Partnership</option>
                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                        <option value="Project office">Project office</option>
                                        <option value="Branch office">Branch office</option>
                                        <option value="Liaison office">Liaison office</option>
                                        <option value="HUF(Hindu Undivided Family)">HUF(Hindu Undivided Family)</option>
                                        <option value="other">other</option>
                                    </select>
                                </div>

                            )}

                            {entity === 'company' && (
                                <div>
                                    <p>What is the name of the company which is the Franchisor under the agreement?</p>
                                    <input type="text" value={companyName} onChange={(e) => { setCompanyName(e.target.value); scrollToElement('companyName') }} placeholder='E.g.NID Tech private limited' />
                                    <p>Which country is the Franchisor incorporated in?</p>
                                    <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} placeholder='E.g. india' />

                                    <p>What is the name of the person signing on behalf of the Franchisor?</p>
                                    <input type="text" value={signName} onChange={(e) => {
                                        setSignName(e.target.value);
                                        scrollToElement('signName')
                                    }} placeholder='E.g.rohit sharma' />

                                    <p>What is the designation of the person signing on behalf of the Franchisor?</p>
                                    <input type="text" value={designation} onChange={(e) => { setDesignation(e.target.value); scrollToElement('designation') }} placeholder='E.g.manager,CEO' />

                                </div>

                            )}
                        </div>
                    )}
                    {step === 3 && (
                        <div>
                            <p>What is address of the Franchisor?</p>
                            <input type="text" value={address} onChange={(e) => { setAddress(e.target.value); scrollToElement('address') }} placeholder='E.g.No.6,Road 8,Mumbai-20000' />

                            <p> Please enter email address of Franchisor.</p>
                            <input type="email" value={email} onChange={(e) => { setEmail(e.target.value); scrollToElement('email') }} placeholder='Franchisor@gmail.com' />

                            <p>Will this address be also used to receive official notices under this agreement from the other party?</p>
                            <input type="radio" id="yes" name="answer" value="yes" checked={answer === 'yes'} onChange={handleAswerChange} />
                            <label>Yes</label><br />
                            <input type="radio" id="no" name="answer" value="no" checked={answer === 'no'} onChange={handleAswerChange} />
                            <label >No</label><br />
                        </div>
                    )}
                    {step === 4 && (
                        <div>
                            {answer === 'no' && (
                                <>
                                    <p>What is the address of the Franchisee where notices are to be sent?</p>
                                    <textarea cols="20" rows="4" value={fullAddress} placeholder='E.g.No.6,Road 8,Mumbai-20000' onChange={(e) => setFullAddress(e.target.value)}></textarea>

                                </>
                            )}
                            <p className='mt-5'>
                                What is the nature of entity of the Franchisee (i.e. the entity who is agreeing to abide by certain terms to use someone else's business model)?
                            </p>
                            <select name="entityFor_Franchisee" id="entityFor_Franchisee" value={entityFor_Franchisee} onChange={handle_entityFor_FranchiseeChange}>
                                <option value="companyFor_Franchisee">company</option>
                                <option value="individualFor_Franchisee">individual</option>
                                <option value="otherFor_Franchisee">other</option>
                            </select>

                        </div>
                    )}
                    {step === 5 && (
                        <div className=''>
                            {entityFor_Franchisee === 'individualFor_Franchisee' && (
                                <div>
                                    <p>What is the name of the Franchisee?</p>
                                    <input type="text" value={name_of_Franchisee} onChange={(e) => { setName_of_Franchisee(e.target.value); scrollToElement('firstcontent') }} placeholder='E.g.rohit sharma' />
                                    <p>What is the gender of the Franchisee?</p>
                                    <input type="radio" id="male" name="gender" value="male" checked={gender_of_Franchisee === 'male'} onChange={handle_geneder_Franchisee_Change} />
                                    <label>Male</label><br />
                                    <input type="radio" id="female" name="gender" value="female" checked={gender_of_Franchisee === 'female'} onChange={handle_geneder_Franchisee_Change} />
                                    <label >Female</label><br />
                                    {gender_of_Franchisee === 'female' && (
                                        <div>
                                            <p>Is the Franchisee married?</p>
                                            <input type="radio" id="yes" name="married" value="yes" checked={married_of_Franchisee === 'yes'} onChange={handle_married_Franchisee_Change} />
                                            <label>Yes</label><br />
                                            <input type="radio" id="no" name="married" value="no" checked={married_of_Franchisee === 'no'} onChange={handle_married_Franchisee_Change} />
                                            <label >No</label><br />
                                            {married_of_Franchisee === 'no' && (
                                                <div>
                                                    <p>What is the father's name of the Franchisee?</p>
                                                    <input type="text" value={Franchisee_father} onChange={(e) => setFranchisee_father(e.target.value)} placeholder='E.g.rohit sharma' />
                                                </div>
                                            )}
                                            {married_of_Franchisee === 'yes' && (
                                                <div>
                                                    <p>  What is the husband's name of the Franchisee?</p>
                                                    <input type="text" value={Franchisee_husband} onChange={(e) => setFranchisee_husband(e.target.value)} placeholder='E.g.rohit sharma' />
                                                </div>
                                            )}
                                        </div>

                                    )}
                                    {gender_of_Franchisee === 'male' && (
                                        <div>
                                            <p>What is the father's name of the Franchisee?</p>
                                            <input type="text" value={Franchisee_father} onChange={(e) => setFranchisee_father(e.target.value)} placeholder='E.g.rohit sharma' />
                                        </div>
                                    )}
                                </div>

                            )}

                            {entityFor_Franchisee === 'companyFor_Franchisee' && (
                                <div>
                                    <p>What is the name of the Franchisee?</p>
                                    <input type="text" value={name_of_Franchisee} onChange={(e) => { setName_of_Franchisee(e.target.value); scrollToElement('firstcontent') }} placeholder='E.g.NID private limited' />

                                    <p>Which country is the Franchisee incorporated in?</p>
                                    <input type="text" value={country_of_Franchisee} onChange={(e) => setCountry_of_Franchisee(e.target.value)} placeholder='E.g. india' />

                                    <p>What is the name of the person signing on behalf of the Franchisee?</p>
                                    <input type="text" value={signName_of_Franchisee} onChange={(e) => setSignName_of_Franchisee(e.target.value)} placeholder='E.g.rohit sharma' />

                                    <p>What is the designation of the person signing on behalf of the Franchisee?</p>
                                    <input type="text" value={designation_of_Franchisee} onChange={(e) => setDesignation_of_Franchisee(e.target.value)} placeholder='E.g.manager,CEO' />

                                </div>
                            )}

                            {entityFor_Franchisee === 'otherFor_Franchisee' && (
                                <div>
                                    <p>What is the name of the Franchisee?</p>
                                    <input type="text" value={name_of_Franchisee} onChange={(e) => { setName_of_Franchisee(e.target.value); scrollToElement('firstcontent') }} placeholder='E.g.NID private limited' />

                                    <p>What is the nature of the entity which is the Franchisee?</p>
                                    <select name="entity" id="selectOther" value={selectOther_Franchisee} onChange={handle_selectOther_Franchisee}>
                                        <option value="partnership">Partnership</option>
                                        <option value="Society">Society</option>
                                        <option value="Trust">Trust</option>
                                        <option value="Limited liability Partnership">Limited liability Partnership</option>
                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                        <option value="Project office">Project office</option>
                                        <option value="Branch office">Branch office</option>
                                        <option value="Liaison office">Liaison office</option>
                                        <option value="HUF(Hindu Undivided Family)">HUF(Hindu Undivided Family)</option>
                                        <option value="other">other</option>
                                    </select>
                                </div>

                            )}
                        </div>
                    )}
                    {step === 6 && (
                        <div>
                            <p>What is address of the Franchisee?</p>
                            <input type="text" value={address_of_Franchisee} onChange={(e) => setAddress_of_Franchisee(e.target.value)} placeholder='E.g.No.6,Road 8,Mumbai-20000' />

                            <p> Please enter email address of Franchisee.</p>
                            <input type="email" value={email_of_Franchisee} onChange={(e) => { setEmail_of_Franchisee(e.target.value); scrollToElement('email') }} placeholder='Franchisor@gmail.com' />
                            <p>Describe the franchisor business in detail, being the overall business being conducted by the Franchisor.</p>
                            <textarea cols="20" rows="4" placeholder='E.g. Owns and operates educational institutions for the ages 3-5 i.e. pre schools in various cities
across India; Owns and operates a chain of fast food restaurant where Indian chaat and sweets are sold.' value={franchisorBusiness} onChange={(e) => { setFranchisorBusiness(e.target.value); scrollToElement('firstcontent') }}></textarea>

                            <p>What is the franchisor's brand name, or the branded mark under which the franchisor operates (i.e. something like "McDonald's" is a brand name or "Starbucks")?</p>
                            <input type="text" value={brandName} onChange={(e) => { setBrandName(e.target.value); scrollToElement('firstcontent') }} placeholder='E.g.No.6,Road 8,Mumbai-20000' />



                        </div>
                    )}
                    {step === 7 && (
                        <div>

                            <p>Does the franchisor sell products? If the franchisor is only a service-oriented business, and does not sell products but instead licenses services, click "No.</p>
                            <input type="radio" id="yesRadio" name="sellProducts" value="yes" checked={sellProducts === 'yes'} onClick={handlesellProductsClick} />
                            <label htmlFor="yesRadio">Yes</label><br />
                            <input type="radio" id="noRadio" name="sellProducts" value="no" checked={sellProducts === 'no'} onClick={handlesellProductsClick} />
                            <label htmlFor="noRadio">No</label><br />

                            <p>Are there any minimum requirements (such as requirements relating to share capital, ownership etc.) which must be satisfied by the Franchisee?</p>
                            <input type="radio" id="yes1Radio" name="sellProducts1" value="yes" checked={sellProducts1 === 'yes'} onClick={handlesellProducts1Click} />
                            <label htmlFor="yes1Radio">Yes</label><br />
                            <input type="radio" id="no1Radio" name="sellProducts1" value="no" checked={sellProducts1 === 'no'} onClick={handlesellProducts1Click} />
                            <label htmlFor="no1Radio">No</label><br />

                            <p>Will the franchise be exclusive i.e. is the franchisee the only entity that will be allowed to use the franchisor's business model in the specific territory or location?</p>
                            <input type="radio" id="Non-exclusive" name="permission" value="Non-exclusive" checked={exclusive === 'Non-exclusive'} onChange={handle_exclusive_Change} />
                            <label htmlFor="Non-exclusive">Non-exclusive</label><br />
                            <input type="radio" id="Exclusive" name="permission" value="Exclusive" checked={exclusive === 'Exclusive'} onChange={handle_exclusive_Change} />
                            <label htmlFor="Exclusive">Exclusive</label><br />
                        </div>
                    )
                    }
                    {step === 8 && (
                        <div>
                            <p>
                                Will the franchisee be approved to have a franchised business in one specific location, like one building or one lot, or more like a broad approved territory?
                            </p>
                            <input type="radio" id="radioTerritory" name="permission1" value="Territory" checked={Territory === 'Territory'} onClick={handleTerritoryChange} />
                            <label htmlFor="Territory">Territory</label><br />
                            <input type="radio" id="radioLocation" name="permission1" value="Location" checked={Territory === 'Location'} onClick={handleTerritoryChange} />
                            <label htmlFor="Location">one Location</label><br />
                            {Territory == "Location" && (
                                <div id="location">
                                    <p>What is the address of the only approved location from which the Franchisee is permitted to operate?</p>
                                    <input type="text" value={showLocationSelect} onChange={(e) => { setshowLocationSelect(e.target.value) }} placeholder="Approved Location Address" />
                                </div>
                            )}
                            {Territory == "Territory" && (
                                <div id="territory">
                                    <p>Enter a description of the territory, being as specific as possible, where the franchisee may operate the new business.</p>
                                    <input type="text" value={showterritorySelect} onChange={(e) => { setshowterritorySelect(e.target.value) }} placeholder="Territory Description" />

                                </div>
                            )}

                            <p>
                                Will the Franchisee have rights to sublicense the operation of the new franchisee business i.e. give out rights to third parties to operate it?
                            </p>
                            <input type="radio" id="yes2Radio" name="agreed" value="yes" checked={agreed === 'yes'} onClick={handleAgreedclick} />
                            <label htmlFor="yes2Radio">Yes</label><br />
                            <input type="radio" id="no2Radio" name="agreed" value="no" checked={agreed === 'no'} onClick={handleAgreedclick} />
                            <label htmlFor="no2Radio">No</label><br />

                            <p>Are there any conditions to be fulfilled by the Franchisee before this Agreement can come into effect?</p>
                            <input type="radio" id="yes2Radio" name="cause3" value="yes" checked={cause3 === 'yes'} onClick={handleClause3chnage} />
                            <label htmlFor="yes2Radio">Yes</label><br />
                            <input type="radio" id="no2Radio" name="cause3" value="no" checked={cause3 === 'no'} onClick={handleClause3chnage} />
                            <label htmlFor="no2Radio">No</label><br />



                        </div>
                    )}
                    {step === 9 && (
                        <div>
                            <p>What are the products being sold by the Franchise business?</p>
                            <input type="text" value={sold} onChange={(e) => {setSold(e.target.value);scrollToElement('clause1')}} placeholder="E.g sneaker of label of 'Tike'" />

                            {cause3 === "yes" && (
                                <div>
                                    <p>
                                        What are the conditions to be fulfilled or completed by the Franchisee before this Agreement can come into effect?
                                    </p>
                                    <textarea cols="20" rows="4" placeholder='E.g. 1. The Franchisee shall procure a no- objection from its existing lenders for the proposed new franchisee business;
                                2. The Franchisee shall have identified and entered into an initial understanding of the premises where operations will be carried out'
                                        value={cause3text} onChange={(e) => setcause3text(e.target.value)}></textarea>

                                    <p>
                                        If the conditions are not fulfilled by a certain date, will the agreement be terminated?
                                    </p>
                                    <input type="radio" id="yes3Radio" name="cause3" value="yes" checked={cause3 === 'yes'} onClick={handleClausePeregraphChange} />
                                    <label htmlFor="yes3Radio">Yes</label><br />
                                    <input type="radio" id="no3Radio" name="cause3" value="no" checked={cause3 === 'no'} onClick={handle_No_ClausePeregraphChange} />
                                    <label htmlFor="no3Radio">No</label><br />
                                </div>
                            )}
                            <p>
                                (Optional) If any initial upfront fee (i.e. committment fee) has been paid/is to be paid by the Franchisee to the Franchisor please enter the same here (in Indian Rupees). This will be known as initial franchise fee (i.e. committment fee).
                            </p>
                            <input type="text" value={fees} onChange={(e) => {setFees(e.target.value); scrollToElement('fees')}} placeholder='E.g.4000000' />


                        </div>
                    )}
                    {step === 10 && (
                        <div>
                            {fees && (
                                <>
                                    <p>
                                        Has the initial franchise fee (i.e. committment fee) already been paid prior to signing of the agreement?
                                    </p>
                                    <input type="radio" id="yes4Radio" name="feesvalue" value="yes" checked={feesValue === 'yes'} onClick={handlefeesValueChange} />
                                    <label htmlFor="yes4Radio">Yes</label><br />
                                    <input type="radio" id="no4Radio" name="feesvalue" value="no" checked={feesValue === 'no'} onClick={handlefeesValueChange} />
                                    <label htmlFor="no4Radio">No</label><br />

                                    {feesValue === 'yes' && (
                                        <>
                                            <p>
                                                Please specify the manner in which the initial franchise fee (i.e. commitment fee) has been paid.
                                            </p>
                                            <textarea cols="20" rows="4" placeholder='E.g.At the time signing of the term sheet between the parties. At the time signing the NDA Agreement between parties'
                                                value={feesAgree} onChange={(e) => setfeesAgree(e.target.value)}></textarea>
                                        </>
                                    )}
                                    {feesValue === 'no' && (
                                        <>
                                            <p>
                                                Please specify when the the initial franchise fee (i.e. committment fee) has to be paid
                                            </p>
                                            <textarea cols="20" rows="4" placeholder='E.g. Within 10  days from signing of this Agreement'
                                                value={feesAgree} onChange={(e) => setfeesAgree(e.target.value)}></textarea>
                                        </>
                                    )}
                                </>
                            )}
                            <p>
                                Describe how the periodic franchise fee is to be paid by the Franchisee to the Franchisor under the Agreement.
                            </p>
                            <textarea cols="20" rows="4" placeholder='E.g. Franchisee shall pay to the Franchisor 10% of gross revenues each month arising from the new franchise business. The fee shall be paid by the 10th of the next month.'
                                value={periodic} onChange={(e) => setperiodic(e.target.value)}></textarea>
                          
                        </div>
                    )}
                    {step === 11 && (
                        <>
                          <p>(Optional) If there are any late payment penalties for the franchise fees, enter them here.</p>
                            <textarea cols="20" rows="4" placeholder='E.g. Franchisee shall pay 8% interest on outstanding amounts in case of delay in payment till the date the amounts are paid in full.'
                                value={penalties} onChange={(e) => setpenalties(e.target.value)}></textarea>

                            <p>Gross sales are the overall sales that the franchisee makes. When will these be reported to the franchisor (normally, this is a significant element of franchise agreements so that the franchisor knows how the business is performing)?</p>
                            <select name="gross" id="gross" value={gross} onChange={handleGrossChange}>
                                <option value="weekly">Weekly</option>
                                <option value="fortnightly">Fortnightly</option>
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="other">Other</option>
                            </select>

                            <p>The franchisee will have to use the franchisor's intellectual property (like brand name, logo, etc). There is sometimes a royalty fee associated with this in franchise agreements. Will any royalty payment be made by Franchisee to Franchisor?</p>
                            <input type="radio" id="yes5Radio" name="royalty" value="yes" checked={royalty === 'yes'} onClick={handleroyaltyChange} />
                            <label htmlFor="yes5Radio">Yes</label><br />
                            <input type="radio" id="no5Radio" name="royalty" value="no" checked={royalty === 'no'} onClick={handleroyaltyChange} />
                            <label htmlFor="no5Radio">No</label><br />

                        </>
                    )}
                    {step === 12 && (
                        <>
                            <p>What is the date on which the agreement will be signed?</p>
                            <input type="date" value={agreementSignDate} onChange={(e) => {setagreementSignDate(e.target.value); scrollToElement('agreementSign')}} />

                            <p>Enter the specific standards of the franchised business that the franchisee needs to adhere to (usually this includes things like cleanliness of the space, employee hiring standards, specific layout, etc.)</p>
                            <textarea cols="20" rows="4" placeholder='E.g. Franchisee shall only hire people who are graduates,
      Franchisee shall maintain the same color scheme as followed in its outlets by the Will specific operating Franchisor'
                                value={Agreement_Franchisee} onChange={(e) =>{ setAgreement_Franchisee(e.target.value); scrollToElement('agreementSign')}}></textarea>
                            <p>
                                Will specific operating procedures/manuals be provided by the Franchisor to Franchisee?
                            </p>
                            <input type="radio" id="yes6Radio" name="procedures" value="yes" checked={procedures === 'yes'} onClick={handleproceduresChange} />
                            <label htmlFor="yes6Radio">Yes</label><br />
                            <input type="radio" id="no6Radio" name="procedures" value="no" checked={procedures === 'no'} onClick={handleproceduresChange} />
                            <label htmlFor="no6Radio">No</label><br />
                        </>
                    )}
                    {step === 13 && (
                        <>
                            <p>How will the expiration date for the agreement be determined (there will be options regarding renewal on following screens)?</p>
                            <select name="expiration" id="expiration" value={expirationDate} onChange={handleExpirationChange}>
                                <option value="anniversary of opening">anniversary of opening</option>
                                <option value="A specific date">A specific date</option>
                                <option value="A specific time period from the date the agreement is singed">A specific time period from the date the agreement is singed</option>
                            </select>
                            {expirationDate == "A specific date" && (
                                <>
                                    <p>Choose the date the agreement will expire</p>
                                    <input type="date" id="expireDate" value={expireDate} onChange={(e) => setexpireDate(e.target.value)} />
                                </>
                            )}
                            {expirationDate == "anniversary of opening" && (
                                <>
                                    <p>What anniversary of the opening date will this agreement naturally expire?</p>
                                    <select name="anniversary" id="anniversary" value={anniversary} onChange={handleAnniversaryChange}>
                                        <option value="1st">1st</option>
                                        <option value="2nd">2nd</option>
                                        <option value="3rd">3rd</option>
                                        <option value="4rth">4rth</option>
                                        <option value="5th">5th</option>
                                        <option value="6th">6th</option>
                                        <option value="7th">7th</option>
                                        <option value="8th">8th</option>
                                        <option value="9th">9th</option>
                                        <option value="10th">10th</option>
                                        <option value="other">other</option>
                                    </select>
                                </>
                            )}
                            {expirationDate == "A specific time period from the date the agreement is singed" && (
                                <>
                                    <p>Please specify the time period when this agreement will expire.</p>
                                    <textarea cols="20" rows="4" placeholder='E.g.6 month after the the 5th anniversary of opening of the 1st restaurant by the Franchisee'
                                        value={Agreement_Expire} onChange={(e) => setAgreement_Expire(e.target.value)}></textarea>
                                </>
                            )}
                        </>
                    )}
                    {step === 14 && (
                        <>
                            <p>
                                What time period prior to expiry of the agreement can a renewal be requested by the Franchisee (if it so desires)?
                            </p>
                            <input type="text" value={timePeriod} onChange={(e) => {setTimePeriod(e.target.value); scrollToElement('renewal')}} placeholder='E.g. 60day; 3months' />

                            <p>Are there any specific conditions to be met by the Franchisee in order for the Franchisor to approve the renewal of the agreement?</p>
                            <input type="radio" id="yes7Radio" name="approveRenewal" value="yes" checked={approveRenewal === 'yes'} onClick={handle_approveRenewal_Change} />
                            <label htmlFor="yes7Radio">Yes</label><br />
                            <input type="radio" id="no7Radio" name="approveRenewal" value="no" checked={approveRenewal === 'no'} onClick={handle_approveRenewal_Change} />
                            <label htmlFor="no7Radio">No</label><br />
                            {approveRenewal === "yes" && (
                                <>
                                    <p>Please list the specific conditions to be met by the Franchisee in order for the Franchisor to approve the renewal of the agreement.</p>
                                    <textarea cols="20" rows="4" placeholder='E.g. 1. The Franchisee shall have undertaken
average sales worth at least Rs1,00,000,000 in the entire duration of the Franchise period.2. Franchisee must not have been late in making any payments in the 12 months preceding the Agreement'
                                        value={approveRenewaltext} onChange={(e) => setApproveRenewaltext(e.target.value)}></textarea>
                                </>
                            )}
                        </>
                    )}
                    {step === 15 && (
                        <>
                            <p>
                                Is the Franchisor training the Franchisee (or their employees, contractors, etc.) prior to the opening date of the business?
                            </p>
                            <input type="radio" id="yes8Radio" name="training" value="yes" checked={training === 'yes'} onClick={handle_training_Change} />
                            <label htmlFor="yes8Radio">Yes</label><br />
                            <input type="radio" id="no8Radio" name="training" value="no" checked={training === 'no'} onClick={handle_training_Change} />
                            <label htmlFor="no8Radio">No</label><br />
                            <p>
                                Will the Franchisor be providing Franchisee with plans and other specifications for the physical layout of the new business?
                            </p>
                            <input type="radio" id="yes9Radio" name="physical" value="yes" checked={physical === 'yes'} onClick={handle_physical_Change} />
                            <label htmlFor="yes9Radio">Yes</label><br />
                            <input type="radio" id="no9Radio" name="physical" value="no" checked={physical === 'no'} onClick={handle_physical_Change} />
                            <label htmlFor="no9Radio">No</label><br />
                            <p>
                                Will the Franchisor be undertaking a final investigation of the new franchisee business to ensure it conforms to all of franchisor's requirements before it opens?
                            </p>
                            <input type="radio" id="yes10Radio" name="investigation" value="yes" checked={investigation === 'yes'} onClick={handle_investigation_Change} />
                            <label htmlFor="yes10Radio">Yes</label><br />
                            <input type="radio" id="no10Radio" name="investigation" value="no" checked={investigation === 'no'} onClick={handle_investigation_Change} />
                            <label htmlFor="no10Radio">No</label><br />
                        </>
                    )}
                    {step === 16 && (
                        <>
                            <p>
                                Will the Franchisor provide opening assistance prior to and during the opening of the new franchisee business?
                            </p>
                            <input type="radio" id="yes11Radio" name="preOpening" value="yes" checked={preOpening === 'yes'} onClick={handle_preOpening_Change} />
                            <label htmlFor="yes11Radio">Yes</label><br />
                            <input type="radio" id="no11Radio" name="preOpening" value="no" checked={preOpening === 'no'} onClick={handle_preOpening_Change} />
                            <label htmlFor="no11Radio">No</label><br />
                            <p>
                                Will the Franchisor provide ongoing advice and assistance to franchisee throughout this agreement?
                            </p>
                            <input type="radio" id="yes12Radio" name="ongoing" value="yes" checked={ongoing === 'yes'} onClick={(e) => setongoing(e.target.value)} />
                            <label htmlFor="yes12Radio">Yes</label><br />
                            <input type="radio" id="no12Radio" name="ongoing" value="no" checked={ongoing === 'no'} onClick={(e) => setongoing(e.target.value)} />
                            <label htmlFor="no12Radio">No</label><br />
                        </>
                    )}
                    {step === 17 && (
                        <>
                            <p>(Optional) If there are any other obligations the Franchisor will be undertaking towards the Franchisee, enter them here.</p>
                            <textarea cols="20" rows="4" placeholder='E.g. Franchisor will order the physical equipment which is required at the location by the Franchisee.'
                                value={obligations} onChange={(e) => setobligations(e.target.value)}></textarea>

                            <p>Please specify how the Franchisee can use the properietary marks of the Franchisor. Enter a detailed description of all the ways the franchisee can and is expected to use the intellectual property of the franchisor (things like the brand name, how it should be displayed, if there are any specific brand colors, etc.)</p>
                            <textarea cols="20" rows="4" placeholder='E.g. Franchisee shall display the Franchisors brand name "Baltiram" prominently in orange and green colors at the entrance of the restaurant and on the top floor of building where the restaurant is situated'
                                value={properietary} onChange={(e) => {setproperietary(e.target.value); scrollToElement('properietary')}}></textarea>
                        </>
                    )}
                    {step === 18 && (
                        <>
                            <p>
                                Check this box if the agreement should restrain the Franchisee from setting up a competing business or being employed in a competing business for a while (non-compete clause):
                            </p>
                            <div className="form-check">
                                <input
                                    className="form-check-input mx-5"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckIndeterminate"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />

                            </div>
                            {isChecked === true && (
                                <>
                                    <p>
                                        What is the period for which the Franchisee should be restrained from setting up a competing business or carrying out competing activities (should be a reasonable period)?
                                    </p>
                                    <input type='text' value={restrained} onChange={(e) => {setrestrained(e.target.value); scrollToElement('non-competition')}} placeholder='E.g.  6 month, 1 year' />

                                    <p>What is the territory in which the Franchisee should be restrained from setting up a competing business or carrying out competing activities?</p>
                                    <input type='text' value={restrainedAddress} onChange={(e) => {setrestrainedAddress(e.target.value); scrollToElement('non-competition')}} placeholder='E.g.  6 month, 1 year' />
                                </>
                            )}
                        </>
                    )}
                    {step === 19 && (
                        <>
                            <p>Will prior notice be required for inspection of Franchisee's premises by the Franchisor?</p>
                            <input type="radio" id="yes13Radio" name="premises" value="yes" checked={premises === 'yes'} onClick={handlepremisesChange} />
                            <label htmlFor="yes13Radio">Yes</label><br />
                            <input type="radio" id="no13Radio" name="premises" value="no" checked={premises === 'no'} onClick={handlepremisesChange} />
                            <label htmlFor="no13Radio">No</label><br />
                            {premises === 'yes' && (
                                <>
                                    <p>What is the notice to be given by the Franchisor to Franchisee prior to inspection?</p>
                                    <input type="text" value={premisesDay} onChange={(e) => setPremisesDay(e.target.value)} placeholder='E.g. 2days, 3 business days' />
                                </>
                            )}
                            <p>(Optional) Enter detailed and specific information about what insurance coverage will be required by Franchisor for Franchisee to maintain on the business, including coverage amounts and if applicable, companies to purchase from.</p>
                            <textarea cols="20" rows="4" placeholder='E.g. The Franchisee shall obtain liability insurance and workers insurance for an amount of at least Rs. 2,00,000.'
                                value={insurance} onChange={(e) => setinsurance(e.target.value)}></textarea>
                        </>
                    )}
                    {step === 20 && (
                        <>
                            <p>
                                Will indemnity be given by both parties (i.e. each party will compensate the other party in case of any loss which arises by action of the party: 'twoway') or will it be given only by Franchisee (i.e. 'oneway')?
                            </p>
                            <input type="radio" id="twoWay" name="way" value="twoWay" checked={way === 'twoWay'} onClick={handleWayChange} />
                            <label htmlFor="twoWay">twoWay</label><br />
                            <input type="radio" id="oneWay" name="way" value="oneWay" checked={way === 'oneWay'} onClick={handleWayChange} />
                            <label htmlFor="oneWay">oneWay</label><br />
                            <p>
                                Are there any other contracts entered into between the parties together with this Franchise Agreement (such as a MOU or Service Agreement or Joint Venture Agreement)?
                            </p>
                            <input type="radio" id="yesRadio14" name=" JointVenture" value="yes" checked={JointVenture === 'yes'} onClick={handleJointVentureChange} />
                            <label htmlFor="yesRadio14">Yes</label><br />
                            <input type="radio" id="noRadio14" name="JointVenture" value="no" checked={JointVenture === 'no'} onClick={handleJointVentureChange} />
                            <label htmlFor="noRadio14">No</label><br />

                        </>
                    )}
                    {step === 21 && (
                        <>
                            {JointVenture === 'yes' && (
                                <>
                                    <p>What is the kind of agreement already entered into between the parties together with this Franchise Agreement?</p>
                                    <input type='text' value={JointVentureText} onChange={(e) => setJointVentureText(e.target.value)} placeholder='E.g. services agreement; letter of intent' />
                                    <p>What is the date of the other agreement entered into between the parties?</p>
                                    <input type='date' value={JointVentureDate} onChange={(e) => setJointVentureDate(e.target.value)} />
                                    <p>
                                        Check this box if the parties want to have arbitration as a dispute resolution mechanism (arbitration being a process where parties appoint a person as arbitrator and settle the dispute out of court):
                                    </p>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input x-5"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckIndeterminate"
                                            checked={isCheckedMechanism}
                                            onChange={handleCheckedMechanismChange}
                                        />

                                    </div>
                                </>
                            )}
                             {isCheckedMechanism === true && (
                                <>
                                    <p>Where will the arbitration be held?</p>
                                    <input type='text' value={arbitration} onChange={(e) => setarbitration(e.target.value)} placeholder="E.g. Delhi,india" />

                                    <p>
                                        Enter the place where the courts will have jurisdiction over the Franchisee agreement. Please choose a place where one of the parties resides or conducts business or where the agreement is executed or franchise will operate.
                                    </p>
                                    <input type='text' value={courts} onChange={(e) => {setcourts(e.target.value);scrollToElement('courts')}} placeholder="E.g. Mumbai,india" />

                                </>
                            )}
                            {isCheckedMechanism === false && (
                                <>
                                    <p>
                                        Enter the place where the courts will have jurisdiction over the Franchisee agreement. Please choose a place where one of the parties resides or conducts business or where the agreement is executed or franchise will operate.
                                    </p>
                                    <input type='text' value={courts} onChange={(e) => {setcourts(e.target.value);scrollToElement('courts')}} placeholder="E.g. Mumbai,india" />

                                </>
                            )}
                        </>
                    )}
                    {step === 22 && (
                        <>
                           
                            <p>Will the party winning in a dispute be entitled to recover costs of lawyers etc. from the other party?</p>
                            <input type="radio" id="yes14Radio" name="lawyers" value="yes" checked={lawyers === 'yes'} onClick={handlelawyersChange} />
                            <label htmlFor="yes14Radio">Yes</label><br />
                            <input type="radio" id="no14Radio" name="lawyers" value="no" checked={lawyers === 'no'} onClick={handlelawyersChange} />
                            <label htmlFor="no14Radio">No</label><br />

                            <p>
                                Would assignment of the agreement be permitted by Franchisor or neither party can assign its rights under the agreement?
                            </p>
                            <input type="radio" id="yes15Radio" name="assign" value="yes" checked={assign === 'yes'} onClick={handleAssignChange} />
                            <label htmlFor="yes15Radio">Only Franchisor can assign</label><br />
                            <input type="radio" id="no15Radio" name="assign" value="no" checked={assign === 'no'} onClick={handleAssignChange} />
                            <label htmlFor="no15Radio">No Party can assign</label><br />
                        </>
                    )}
                    {step === 23 && (
                        <>
                            <p>Check this box if the agreement should restrain the parties from approaching each other's clients for a certain period of time (non-solicitation clause).</p>
                            <div className="form-check">
                                <input
                                    className="form-check-input mx-5"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckIndeterminate"
                                    checked={isSolicitation}
                                    onChange={handleSolicitationChange}
                                />

                            </div>
                            {isSolicitation === true && (
                                <>
                                    <p>
                                        What is the time period for which a party shall not be allowed to approach the other party's clients?
                                    </p>
                                    <input type='text' value={client} onChange={(e) => setClient(e.target.value)} placeholder='E.g.60day, 6month, 1year' />
                                </>
                            )}
                        </>
                    )}
                    <div className='mt-5'>
                        <button className='mx-2 btn btn-warning' onClick={handlePrevious} disabled={step === 1}>Previous</button>
                        {step === 23 ? (
                            <button className='btn btn-primary' onClick={generatePDF}>Generate PDF</button>
                        ) : (

                            <button className='btn btn-info' onClick={handleNext} disabled={step === 23}>Next</button>

                        )}
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-3'></div>

                    <div id="content" className="col-8 mb-5 text-start">

                        <h1 className='text-center mb-5'>FRANCHISE AGREEMENT</h1>
                        <p id="date">This Franchise Agreement, hereinafter referred to as "Agreement" is entered into and made effective on
                            <span className='input-content'> {formattedDate(date)}</span>
                            (Commencement Date") by and between the following parties
                        </p>

                        {entity === 'individual' && (
                            <p id="individual">
                                {name} <span>{gender === 'female' && married === 'yes' ? 'wife' : (gender === 'female' && married === 'no' ? 'daughter' : 'son')}</span> of
                                <span className='input-content'>{married === 'yes' ? husbandname : fathername} </span> resident of
                                <span className='input-content'> {address}</span> (hereinafter referred to as the 'Franchisor" which expression shall unless excluded by or repugnant to the subject or context be deemed to include its legal heirs, administrators and permitted assigns) of the ONE PART
                            </p>
                        )}
                        {entity === 'company' && (
                            <p className='company'>
                                <span className='input-content'> {companyName}</span>  a company incorporated under the laws of
                                <span className='input-content'> {country}</span>  having its registered office at
                                <span id='address' className='input-content'> {address}</span> (hereinafter referred to as the "Franchisor" which expression shall unless excluded by or repugnant to the
                                subject or context be deemed to include its successors-in-interest and permitted assigns) of the ONE PART
                            </p>
                        )}
                        {entity === 'other' && (
                            <p id="other">
                                <span className='input-content'> {name}</span>   a  <span className='input-content'> {selectOther}</span> , having its principle place of business
                                at   <span id='address' className='input-content'> {address}</span> , represented by:<span className='input-content'>{signName}</span> having designation:<span className='input-content'>{designation}</span>
                                (hereinafter referred to as the "Franchisor" which expression shall unless excluded by or repugnant to the subject or context be deemed to include its successors-in-interest and permitted assigns) of the ONE PART
                            </p>
                        )}
                        <p className='text-center' id="firstcontent"> AND</p>

                        {entityFor_Franchisee === 'individualFor_Franchisee' && (
                            <p>
                                {name_of_Franchisee} <span>{gender_of_Franchisee === 'female' && married_of_Franchisee === 'yes' ? 'wife' : (gender_of_Franchisee === 'female' && married_of_Franchisee === 'no' ? 'daughter' : 'son')}</span> of
                                <span className='input-content'>{married_of_Franchisee === 'yes' ? Franchisee_husband : Franchisee_father} </span> resident of
                                <span className='input-content'> {address_of_Franchisee}</span> (hereinafter referred to as the "Franchisee" which expression shall unless excluded by or repugnant to the subject or context be deemed to include its legal heirs, administrators and permitted assigns) of the OTHER PART

                            </p>
                        )
                        }
                        {entityFor_Franchisee === 'companyFor_Franchisee' && (
                            <p className='company'>
                                <span className='input-content'> {name_of_Franchisee}</span>  a company incorporated under the laws of
                                <span className='input-content'> {country_of_Franchisee}</span>  having its registered office at
                                <span className='input-content'> {address_of_Franchisee}</span>  (hereinafter referred to as the "Franchisee" which expression shall unless excluded by or repugnant to the subject or context be deemed to include its sucessors-in-interest and permitted assigns) of the OTHER PART
                                Franchisor and Franchisee may be referred to individually as "Party" and collectively as the "Parties."
                            </p>
                        )}
                        {entityFor_Franchisee === 'otherFor_Franchisee' && (
                            <p>
                                <span className='input-content'> {signName_of_Franchisee}</span> having designation: <span className='input-content'> {designation_of_Franchisee}</span>
                                (hereinafter referred to as the "Franchisee" which expression shall unless excluded by or repugnant to the subject or context be deemed to include its successors-in-interest and permitted assigns) of the
                                OTHER PART
                            </p>
                        )}
                        <p>Franchisor and Franchisee may be referred to individually as "Party" and collectively as the "Parties"</p>
                        <p id="product"><b>WHEREAS:</b></p>
                        <p>(A) The franchisor is engaged in the business of:
                            <p>{franchisorBusiness}</p>
                            (hereinafter referred to as "Business)Business using proprietary service</p>
                        <p>(B) The franchoor has developed systems and methods for operating and the and trademarks (hereinafter referred to as "Proprietary Marks" and defined hereunder) designed to allow Franchisor to run the Business
                        </p>
                        <p>(C) Franchisor accepts and allows franchisees to operate businesses according to certain terms and conditions under Franchisor's branded mark of the following :<span className='input-content'>{brandName}</span></p>
                        {sellProducts === 'yes' && (
                            <p>(D) Franchisor selis certain products, as defined below ("Products") in connection with the Business,</p>

                        )}
                        <p>WHEREAS, the System includes elements unique to the Franchisar and the Business, including, but not limited to specifications, technique ques, tranning methods, products, production methods operating methods, designs, colour schemes, fumishings, marketing matenals and business requirertwesta to cornually increase the customer trust and goodwill in Franchisor's Business</p>
                        <p> WHEREAS, the Franchisee desires to establish and operate a business and wishes to obtain a license to use Franchisor's System and Proprietary Marks in order to run the business under Franchisor's guidelines;</p>
                        {sellProducts1 === 'yes' && (
                            <p>WHEREAS the Franchisee has represented and warranted to the Franchisor that it satisfies the minimum franci requirements as set out in Annexure 1;</p>

                        )}
                        <p>WHEREAS, relying on the representations and warranties and the undertaking of the Franchisee, the Franchisor agreed to grant the Franchisee the <span className='input-content'>{exclusive}</span> franchise to establish and operate the business on the terms a conditions as set out herein,</p>
                        <p>NOW, THEREFORE, in consideration of the promises and covenants contained herein, as well as other good and valuable consideration (the receipt and sufficiency of which is hereby acknowledged), the Parties do hereby agre as follows:
                        </p>
                        <p id="clause1"><b className='clause-count'></b> <b>DEFINITIONS:</b></p>
                        <ol>
                            {Territory == "Location" && (
                                <li id='location'>Aproved Location: The Aproved Location is the physical location having the following address:<span className='input-content'>{showLocationSelect}</span> Franchisee has been aliliroved by Franchisor to olien and olierate the New Franchise Business.</li>

                            )}
                            <li>  proprietary Marks: The  proprietary Marks are the marks owned by Franchisor, including but not limited to, Franchisor's branded mark,<span className='input-content'>{brandName}</span> as well as any and all other names, trademarks, service marks, logos, trade dress, or other commercial symbols or coliyrighted materials belonging to Franchisor</li>
                            <li> System: The System shall be defined as the concelitualization, sliecifications, design, marketing, olieration, franchising and licensing of the Franchisor Business, including the exlienditure of resources into the Business, for the liurlioses of commercial ale to the liublic.</li>
                            <li> New Franchisee Business: New Franchisee Business shall be defined as the new business to be owned by the Franchisee, that utilizes Franchisor's System and lirolirietary Marks, oliened liursuant to this Agreement.
                            </li>
                            <li>
                                Term: The Term of the Agreement shall be defined as the lieriod for which this Agreement will run, discussed more sliecifically elsewhere in this Agreement.
                            </li>
                            <li>
                                Gross Sales: Gross Sales shall be defined as all revenue derived from the New Franchisee Business, at regular lirices before any discounts or other allowance, as well as all income of any and every other kind related to the New Franchisee Business, System, or lirolirietary Marks, the fair value of any non-monetary consideration received by Franchisee for any items or sales from the New Franchisee Business, and all liroceeds of business interrulition
                                insurance liolicies for the New Franchisee Business Gross Sales shall not include sales or excise taxes, or other

                                taxes, added to the selling lirice of any item or service collected from customers and given to state or Federal

                                governments Any tax rebates received by Franchisee shall be included in Gross Sales, however, Gross Sales also

                                sliecifically include any charged sales
                            </li>
                            {Territory == "Territory" && (
                                <li id='territory'>
                                    Territory: Territory shall be defined as the sliecific geogralihic location where Franchisee is liermitted to olierate the New Franchisee Business. The Territory is as follows:<span className='inliut-content'>{showterritorySelect}</span>
                                </li>
                            )}

                            <li>
                                Franchisor Marketing Materials: Franchisor Marketing Materials are all the advertising and liromotional materials including liress releases, signs, coliy, concelits, brochures or other information used to advertise or market Franchisor's Business and given to franchisees for the liurlioses of advertising, as well.
                            </li>
                            <li>
                                Operating Procedures: the minimum operating procedures as set out in Annexure 2 as may be amended or updated from time to time, by the Franchisor,
                            </li>
                            <li>
                                Products: The products shall be  defined as the following products with franchisor sells through the bussiness or sells through franchised bussinesses:
                                <span className='input-content'>{sold}</span>
                            </li>
                        </ol>

                        <p id='agree'><b className='clause-count'></b> <b>LIMITED GRANT:</b></p>
                        <p>In consideration of the Franchisee paying the Franchisee Fee and complying with the Operating Procedures, the Franchisor hereby grants a franchise to the Franchisee for operating the New Franchisee Business for the Term on the terms and conditions contained herein and the Franchisee hereby accepts such franchise.
                            {agreed === 'no' && (
                                <span>It is agreed by the Parties that the Franchisee shall not have any right to further sub-franchise the operation of the New Franchisee Business.</span>
                            )}
                        </p>
                        <p>
                            Franchisor hereby grants, and Franchisee accepts under the terms and conditions of this Agreement, a limited, non- transferable, non-exclusive license to use the Proprietary Marks and the System to operate the New Franchisee
                            Business in the Territory for the Term.
                        </p>

                        {cause3 === "yes" && (
                            <div id="clauseContent">
                                <p><b className='clause-count'></b> <b>CONDITIONS PRECEDENT:</b></p>

                                <p>(a) The Parties agree that this Agreement shall come into effect from the date on which the conditions as set out below are satisfied by the Franchisee to the satisfaction of the Franchisor ("Commencement Date") and shall be in force for the Term unless terminated earlier in accordance with the provisions of this Agreement:
                                    <span className='input-content'>{cause3text}</span>
                                </p>
                                <p>
                                    (b) Forthwith upon the completion of all Conditions Precedent, the Franchisee shall notify the Franchisor of the
                                    same, and shall furnish all information and documents as may be required by the Franchisor evidencing such
                                    completion. In the event the Franchisor is satisfied that the Conditions Precedent have been duly completed, it shall
                                    so inform the Franchisee within 7 days of the date of the receipt of the last of the documents/information from the
                                    Franchisor
                                </p>
                                <p id="cause3peragraph">
                                    (c) In the event the Conditions Precedent are not fully complied with to the satisfaction of the Franchisor by or within such extended period as may be agreed to by the Franchisor in writing, the Franchisor shall be entitled (but not obligated) to forthwith terminate this Agreement without any liability whatsoever.
                                </p>
                            </div>
                        )}

                        <p id="reserved"><b className='clause-count'></b> <b>RESERVED RIGHTS:</b></p>
                        <p>Franchisee hereby acknowledges and agrees that this Agreement shall in no way mean that Franchisor's rights limited with regard to Franchisor's Business. Franchisor and its affiliates, parents, subsidiaries, or related businesses hereby retain all rights to develop, operate, promote or otherwise exploit the Business, the System, a the Proprietary Marks. Franchisee additionally acknowledges and agrees that Franchisee is not entitled to any exclusivity or other territorial rights, except as specifically delineated in this Agreement.</p>
                        <p><b className='clause-count'></b> <b>FEES:</b></p>
                        <ol>
                            {fees && (
                                <div id='fees'>
                                    {feesValue === 'no' && (
                                        <li>
                                            Commitment Fee: Franchisee shall pay the following initial franchise fee ("Commitment Fee") to Franchisor for the right to enter this Agreement and in consideration of the New Franchisee Business: Rs.{fees}within the following period:
                                            <span className='input-content'>{feesAgree}</span>
                                        </li>
                                    )}
                                    {feesValue === 'yes' && (
                                        <li> Commitment Fee: The Initial Fee of Rs.<span className='input-content'>{fees}</span> has been paid as follows:
                                            <span className='input-content'>{feesAgree}</span>
                                        </li>


                                    )}

                                    <li> The Franchisee agrees and acknowledges that the Commitment Fee is a one-time and non-refundable fee and shall not be refunded to the Franchisee under any circumstances, including but not limited to termination of this Agreement.</li>

                                    <li> Should Franchisee fail to pay the Initial Fee or any portion of it, Franchisor shall have the absolute right to declare this Agreement null and void and retain any portion of the Initial Fee that Franchisee may have already paid. Franchisor shall then have no additional obligations to Franchisee under this Agreement.</li>

                                </div>
                            )}
                            <li> Franchise Fees. Franchisee shall pay franchise fees ("Franchise Fees") to the Franchisor as follows:
                                <span className='input-content'>{periodic}</span>
                            </li>
                            <li> Franchisee shall report its Gross Sales to Franchisor accurately, on a <span className='input-content' id="grosstext">{gross}</span> basis, or any other periodic time period specified by the Franchisor, in any way that Franchisor requires.
                            </li>
                            {royalty === 'yes' ? (
                                <li id="royaltyText">
                                    Royalties: Franchisee shall pay Franchisor a royalty for use of the Proprietary Marks which shall be as follows:
                                </li>
                            ) : (
                                <></>
                            )}

                        </ol>
                        <p><b className='clause-count'></b> <b>ADVERTISING:</b></p>


                        <p>(a) Franchisee hereby agrees to use and prominently display the Franchisor Marketing Materials and Proprietary Marks solely in connection with the New Franchisee Business,
                        </p>
                        <p>
                            The franchisor may request reports regarding Franchisee's advertising expenditure and activities, at Franchisor's sole and exclusive discretion.
                        </p>
                        <p>
                            (b) Franchisee agrees not to advertise or market the New Franchisee Business in any way which may disparage or detract from the reputation of Franchisor. If Franchisee has not received prior approval for any marketing materials, Franchisee agrees to submit such materials to Franchisor for approval, which Franchisor will approve or deny in a reasonable time period.
                        </p>

                        <p>
                            <b className='clause-count'></b> <b>OPENING DATE:</b>
                        </p>
                        <p>
                            Franchisor and Franchisee agree to estimated opening date of <span className='input-content' id="agreementSign">{formattedDate(agreementSignDate)}</span> ("Opening Date"). Notwithstanding the foregoing, Franchisee agrees not to commence any operations for the New Franchisee Business until Franchisor has approved the specifications of the business as adhering to the terms and conditions of this Agreement.
                        </p>
                        <p id="agreementDate"><b className='clause-count'></b> <b>COMPLIANCE WITH OPERATING PROCEDURES:</b></p>
                        <p>Franchisor hereby agrees to comply with the following operating standards: <span className='input-content'>{Agreement_Franchisee}</span>
                        </p>
                        {procedures === 'yes' ? (
                            <p id='procedures'>
                                Franchisee hereby agrees to strictly comply with Franchisor's System, as outlined by Franchisor to Franchisee. Such information will be given in the form of a manual, training provided or other written document. Any failure of Franchisee to comply with the System standards shall be grounds for termination of this Agreement.

                            </p>
                        ) : (
                            <></>
                        )}

                        <p><b className='clause-count'></b> <b>TERM & TERMINATION:</b></p>
                        <p>
                            The Term of this Agreement shall begin on the Commencement Date listed at the end of this document and will end on the Expiration Date, as defined below unless this is Agreement in terminated sooner as listed in this provision and elsewhere within this Agreement. If the dates set forth at the end of this document are different, this Agreement is to be considered effective as of the date that both Parties have signed the agreement, which may be the later date.
                        </p>
                        <p>The Expiration Date shall be as follows:
                            {expirationDate === "A specific date" && (
                                <span className='input-content'>{expireDate}</span>
                            )}
                            {expirationDate === "anniversary of opening" && (
                                <span className='input-content'>{anniversary} {expirationDate}</span>
                            )}
                            {expirationDate === "A specific time period from the date the agreement is singed" && (
                                <span className='input-content'>{Agreement_Expire} {expirationDate}</span>
                            )}
                        </p>
                        <p>If for any reason, Franchisee does not open the New Franchisee Business by the Opening Date, Franchisor shall be entitled to the following remedies, at Franchisor's sole and exclusive election:
                        </p>
                        <p>(a) Franchisor shall allow Franchisee additional time to open the New Franchisee Business, leaving the Expiration Date as is, or</p>
                        <p>(b) Franchisor shall require Franchisee to enter into a new Franchise Agreement with a new Opening Date and possible new Expiration Date at the sole and exclusive discretion of Franchisor
                        </p>
                        <p>Franchisor shall have the ability to terminate this Agreement for good cause, defined as any material breach of this Agreement Specifically, Franchisee acknowledges and agrees that the following acts (considered a non-exhaustive list, not including each specific material breach of this Agreement) shall be cause for immediate termination
                        </p>
                        <p>(a) Abandonment of the New Franchisee Business for a period of ten (10) consecutive days or any shorter period of time if Franchisor has determined that Franchisee does not intend to continue operation of the New Franchisee Business;
                        </p>
                        <p>
                            (b) Franchisee's bankruptcy or insolvency,
                        </p>
                        <p>
                            (c) Any levy made upon the New Franchisee Business;
                        </p>
                        <p>(d) The right to occupy the physical space of the New Franchisee Business is lost or terminated in any way,
                        </p>
                        <p>
                            (e) The franchisee is convicted of a felony or any criminal misconduct related to the operation of the New Franchisee Business,
                        </p>
                        <p>
                            (f) Franchisor discovers a material misrepresentation made by Franchisee in connection with this Agreement;
                        </p>
                        <p>
                            (g) Franchisee engages in any behaviour which would impair the Franchisor's trademark, trade name, or any other commercially-valuable intellectual property,
                        </p>
                        <p>
                            (h) Franchisee behaves in any manner which would reflect poorly on the reputation and goodwill of Franchisor or fails to act in a commercially reasonable manner, or
                        </p>
                        <p>
                            (i) Franchisee fails to pay any fees, costs, charges or other amounts due under this Agreement
                        </p>
                        <p>
                            The franchisee must be given written notice of termination in advance of such termination and the notice must state the reasons for termination clearly. The franchisee may also be given an opportunity to cure if the termination is not being undertaken as a result of one of the good cause reasons listed above

                        </p>
                        <p><b className='clause-count'></b> <b>RIGHTS & OBLIGATIONS ON TERMINATION</b></p>
                        <p>In the event this Agreement naturally expires or is terminated, Franchisee hereby agrees to undertake the following acts
                        </p>
                        <p>
                            (a) Immediately cease to use the Propnetary Marks and the System for any business and retum any manuals, specifications, forms, memoranda, training materials, advertising materials, instructions, and materials with the Proprietary Marks listed on them to Franchisor, and
                        </p>
                        <p>
                            (b) Remove any signs or advertisements which identify Franchisor or Franchisor's business from the New Franchisee Business and perform any remodelling from Franchisor's business of redecoration which may be required by Franchisor to distinguish it further
                        </p>
                        <p>Franchisor shall retain any and all fees and other monies paid pursuant to this Agreement. Franchisor shall also have the ability, at Franchisor's election, to purchase Franchisee's interest in any lease, physical space, furniture, fixtures, equipment or other tangible assets. The purchase price shall be the lesser of the Franchisee's cost or the then fair market value of such tangible property, as assessed by an independent appraiser.
                        </p>
                        <p>
                            If there are any fees owed to the Franchisor at the expiration or termination of this Agreement, Franchisee shall pay them immediately. If the termination is by reason of the Franchisee's breach or default, such sums shall include any and all damages, costs, and expenses incurred by Franchisor.
                        </p>
                        <p>
                            Franchisor shall retain all rights and remedies after the termination or expiration of this Agreement. Furthermore, such expiration or termination shall not release Franchisee of any of obligations to Franchisor at the time of the expiration or termination nor terminate those obligations and liabilities of Franchisee which, by their nature, survive the expiration or termination of this Agreement.
                        </p>


                        <p id="renewal"> <b className='clause-count'></b> <b>RENEWAL:</b> </p>
                        <p>
                            If Franchisee should like to renew the Parties' relationship at the end of its Term, Franchisee shall provide a written request for renewal to Franchisor at least the following period prior to the Expiration Date<span className='input-content'>{timePeriod}</span>.
                            Such written document shall describe that the particular request is for one additional Term as defined in this Agreement
                        </p>
                        <p>
                            Franchisee hereby acknowledges and agrees that the terms of any agreement to renewal (Renewal Agreement") may be substantially different, including any soyalty or franchise fees and any other terms at Franchisor's sole and exclusive discretion
                        </p>
                        {approveRenewal === "yes" && (
                            <p>In order to be considered for renewal, Franchisee shall have met each of the following condition(s):
                                <span className='input-content'>{approveRenewaltext}</span>
                            </p>

                        )}
                        <p>
                            Despite meeting the above-listed condition(s), Franchisee acknowledges and agrees that Franchisor shall in no way be obligated to issue any Renewal Agreement to Franchisee
                        </p>
                        <p>
                            If Franchisor decides, in Franchisor's sole and exclusive discretion, that a Renewal Agreement shall be offered to Franchisee, Franchisee shall execute such agreement in a timely manner
                        </p>
                        <p>
                            The Parties acknowledge and agree that this Agreement confers no automatic right or other rights to renewal
                            continuation, or a subsequent franchise agreement on or after the Expiration Date and that Franchisor shall have no
                            obligation to continue any relationship with Franchisee after the Expiration Date. If Franchisor elects not to renew
                            written notice must be provided to the Franchisee atleast 180 (one hundred and eighty) days in advance
                        </p>
                        <p>
                            If Franchisee enters into any new lease or extends any lease for tenancy of the New Franchisee Business extending
                            beyond the Term Franchisee acknowledges that Franchisee shall be doing so at its own risk
                        </p>
                        <p id='yes' >
                            <b className='clause-count'></b> <b>FRANCHISOR'S OBLIGATIONS:</b>
                        </p>
                        <ol id="obligation">
                            {training === 'yes' && (
                                <li >
                                    Prior to the Opening Date, Franchisor shall provide initial training for Franchisee and portions of Franchisee's staff, in Franchisor's sole and exclusive discretion. The franchisor is not obligated to train more than one person, but may allow additional staff if Franchisor sees fit.
                                </li>
                            )}
                            {physical === 'yes' && (
                                <li>
                                    Prior to the Opening Date, Franchisor shall provide Franchisee with standard criteria for the design and configurations required for the New franchisee Business, including exterior and interior design and layout, fixtures, furnishings, equipment, and signage.
                                </li>
                            )}
                            {investigation === 'yes' && (
                                <li>
                                    Prior to the Opening Date, Franchisor shall inspect the New Franchisee Business to determine that it reasonably
                                    conforms to the requirements of Franchisor.
                                </li>
                            )}
                            {preOpening === 'yes' && (
                                <li>
                                    Franchisor shall provide certain pre-opening and opening assistance and guidance to Franchisee, as Franchisor
                                    deems appropriate in Franchisor's sole and exclusive discretion.
                                </li>
                            )}

                            <li>
                                The franchisor will continue to make available Franchisor's products for sale to Franchisee, so that Franchisee may effectively run the New Franchisee Business.

                            </li>
                            {ongoing === 'yes' && (
                                <li>
                                    Franchisor shall provide Franchisee with advisory assistance from time to time, including with the provision of information, data, manuals, techniques, and possibly materials regarding the New Franchisee Business and its operations.

                                </li>
                            )}
                            {obligations && (
                                <li>
                                    <span className='input-content'> {obligations}</span>
                                </li>
                            )}
                        </ol>
                        <p><b className='clause-count'></b> <b> RELOCATION OR OUTSIDE SALES</b></p>
                        <p>No part of this Agreement permits Franchisee any rights to relocate the New Franchisee Business, use the System outside the New Franchisee Business, or engage in any services or sell any goods bearing the Propnetary Marks outside the Territory. If Franchisor occasionally agrees to allow Franchisee to engage in business outside the Territory, this shall not be considered a waiver of the foregoing, Franchisor shall have specific requirements applicable to any sales or services outside the Territory.</p>

                        <p><b className='clause-count'></b> <b>PROPRIETARY MARKS:</b></p>
                        <p>
                            (a) Franchisor's Retained Rights. Franchisee hereby acknowledges and accepts that Franchisor is the owner of all right, title and interest in and to the Proprietary Marks. As such, Franchisor agrees to indemnify and hold Franchisee
                            harmless against any claims that the Franchisee's use of the Proprietary Marks infringes on the rights of any third
                            of the claim, as well as cooperate to
                            party. In such a scenario, Franchisee agrees to immediately notify Franchisor of the claim, as well as cooperate to the maximum extent possible in Franchisor's defence and/or settlement of the claim.
                        </p>
                        <p>
                            (b) Franchisee's Use: In conjunction with other provision in this Agreement, Franchisor hereby grants Franchisee a limited, non-exclusive, revocable license to use, reproduce, publicly display, or distribute the Proprietary Marks for the agreed-upon terms set forth in this Agreement. The Proprietary Marks used for any purpose not directly related to these terms must be with the express written permission of Franchisor and may include the payment of additional fees unless otherwise agreed to in writing.
                        </p>
                        <p id="properietary" >
                            (c) Franchisee agrees to use the Proprietary Marks in substantially the same manner as used by Franchisor as part of the System, specifically as follows:
                            {properietary && (
                                <span className='input-content'>{properietary}</span>
                            )}
                        </p>

                        <p>
                            Franchisee may also be requested by Franchisor to identify itself as a licensee or franchisee in a certain manner and must do so.
                        </p>
                        <p>
                            (d) Franchisee may not assign, transfer, sublicense, or otherwise allow any other person or business to use any of the Proprietary Marks and may not hold itself out as an agent of Franchisor through the use of the Proprietary Marks
                        </p>
                        <p>
                            (e) Franchisee will not use, display, or apply for registration with or register with any local, state, or Federal government any names or marks that are similar to any of the Proprietary Marks, in Franchisor's sole and exclusive discretion.

                        </p>
                        <p>
                            (f) Franchisee hereby agrees to comply with all of Franchisor's instructions regarding any required filings or business names, as well as execute and maintain any necessary paperwork to run the New Franchisee Business and use the Proprietary Marks.
                        </p>
                        <p>
                            (g) If any litigation ensues or is threatened regarding the Proprietary Marks against Franchisee, Franchisee shall immediately notify Franchisor in writing and cooperate fully in Franchisor's defence or settlement of the claim Franchisee agrees not to institute any litigation or make any demand or serve any notice of any legal action without first obtaining the written prior consent of Franchisor. The franchisor has the right to bring such action and to join
                            Franchisee
                        </p>
                        <p>
                            (h) Franchisee agrees that Franchisor and any affiliates are the owners of all night, title, and interest in and to the
                            Proprietary Marks, as well as the goodwill associated with such marks, and that such marks are valid to identify the
                            Franchisor's Business and Systern, as well as those additional franchisees who operate using the System.
                        </p>
                        <p>
                            (i) The franchisor may, at Franchisor's sole and exclusive discretion, replace, add, delete or otherwise modify the
                            Proprietary Marks. If Franchisor takes any such action, Franchisee hereby agrees to accept the new Proprietary Marks and bear the cost of confirming the New Franchisee Business for the new Proprietary Marks.

                        </p>
                        <p>
                            (j) Franchisee agrees, now and hereafter, not to attack the ownership, title or rights of Franchisor or Franchisor's affiliates in any of the Proprietary Marks, contest the validity of any of the Proprietary Marks, take any action which could jeopardize or infringe upon the Proprietary Marks or misuse the Proprietary Marks in any way.

                        </p>
                        <p>
                            (k) Franchisee acknowledges and agrees that Franchisee has no ownership interest in any of the Proprietary Marks, the System, or any other intellectual property belonging to Franchisor and/or Franchisor's affiliates, and that any license granted hereunder is non-exclusive and shall be in effect only for the term of this Agreement and Franchisee's relationship with Franchisor. Franchisee further acknowledges and agrees that Franchisee is not owed any intellectual property rights by Franchisor and that this license is revocable at any time.

                        </p>
                        <p>
                            (l) Franchisee may not use any other marks in connection with any of the Proprietary Marks, unless Franchisee has received specific prior written approval from Franchisor.

                        </p>
                        {isChecked === true && (
                            <>
                                <p id="non-competition"><b className='clause-count'></b> <b>NON-COMPETITION:</b></p>

                                <p>During the caurde of this Agreement and for a  periods of
                                    <span className='input-content'>{restrained}</span>  thereafter, Franchisee agrees to refrain from Franchisee During the course of this Agreement and for a period of
                                    engaging, directly or indirectly, in any form of commercial competition (including, but not limited to, through business, marketing, investment or financial activities) with Franchisor in the following territory:
                                    <span className='input-content'>{restrainedAddress}</span>.Franchisee agrees not to engage in any form of commercial competition either single-handedly or through employment or contracting with a third-party or organization. Without limitation to the above, Franchisee shall not use any of the business information given by Franchisor to Franchisee directly or indirectly to procure a
                                    commercial advantage over Franchisor or otherwise use any designs, ideas or concepts created by or belonging to Franchisor without the express
                                    written consent of the Franchisor in the following territory:  <span className='input-content'>{restrainedAddress}</span>.
                                </p>
                            </>
                        )}
                        <p><b className='clause-count'></b> <b>QUALITY CONTROL:</b></p>

                        <p>
                            The franchisor shall have the unconditional right to inspect the New Franchisee Business to assure quality control. The franchisor may send representatives from time to time, for the purposes of observation, examination, and evaluation {premises === 'yes' && (<>with prior notice of <span className='input-content'>{premisesDay}</span></>)}
                            .The franchisor may, at its sole discretion, send Franchisee an inspection report, and demand that Franchisee immediately complies with any and all deficiencies or unsatisfactory conditions. If Franchisee fails to correct any deficiency within the time period written on the inspection report or communicated by Franchisor, Franchisor shall have the right to implement a required fee for Franchisee or terminate this agreement, as Franchisor may see fit.

                        </p>

                        <p><b className='clause-count'></b> <b>NO WAIVER:</b></p>
                        <p>

                            None of the terms of this Agreement shall be deemed to have been waived by any act or acquiescence of Franchisor. Only an additional written agreement can constitute waiver of any of the terms of this Agreement between the Parties. No waiver of any term or provision of this Agreement shall constitute a waiver of any other
                            term or provision or of the same provision on a future date. Failure of Franchisor to enforce any term of this
                            Agreement shall not constitute waiver of such term or any other term.
                        </p>
                        {insurance && (
                            <>
                                <p><b className='clause-count'></b> <b>INSURANCE:</b></p>
                                <p>
                                    During the term of this Agreement, Franchisee shall obtain and maintain insurance coverage with insurance carriers acceptable to Franchisor in accordance with the Franchisor's insurance requirements. The coverage shall begin as soon as Franchisee signs a lease for the New Franchisee Business.
                                </p>
                                <p>
                                    The coverage requirements are as follows:<span className='input-content'>{insurance}</span>
                                </p>
                            </>
                        )}
                        <p><b className='clause-count'></b> <b>FORCE MAJEURE:</b></p>
                        <p>
                            Neither Party is liable for any failure to perform due to causes beyond its reasonable control including, but not
                            limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature and
                            natural disasters, pandemics and other acts which may be due to unforeseen circumstances,
                        </p>
                        <p id="way"><b className='clause-count'></b> <b>INDEMNITY:</b></p>
                        {way === 'twoWay' && (
                            <p>
                                Each Party ("Indemnifying Party") hereby agrees to indemnify and hold the other Party harmless from all damages, costs, attorney's fees or other losses arising out of or relating to the breach of this Franchise Agreement by the Indemnifying Party.
                            </p>
                        )}
                        {way === 'oneWay' && (
                            <p>
                                The Franchisee hereby agrees to indemnify and hold Franchisor harmless from all damages, costs, attorney's fees or other losses arising out of or relating to the breach of this Franchise Agreement by the Franchisee
                            </p>
                        )}

                        <p><b className='clause-count'></b> <b>SEVERABILITY:</b></p>
                        <p>
                            If any provision of this Franchise Agreement shall for any reason be held to be invalid, illegal, or unenforceable in any respect, such invalidity, illegality, or unenforceability shall not affect any other provision thereof, and this Franchise Agreement shall be construed as if such invalid, illegal or unenforceable provision had never been contained herein. Any invalid or unenforceable provision of this Franchise Agreement shall be replaced with a provision that is valid and enforceable and most nearly gives effect to the original intent of the invalid/unenforceable provision.
                        </p>
                        <p><b className='clause-count'></b> <b>ENTIRE AGREEMENT:</b></p>
                        {JointVenture === "yes" && (
                            <p>
                                This Franchise Agreement along with the following document:<span className='input-content'>{JointVentureText}</span>dated
                                <span className='input-content'>{formattedDate(JointVentureDate)}</span> between the Parties constitutes the entire agreement and understanding of
                                the Parties with respect to the subject matter hereof and supersedes any and all prior negotiations, correspondence,
                                agreements, understandings duties or obligations between the Parties with respect to the subject matter hereof.
                            </p>
                        )}
                        {JointVenture === "no" && (
                            <p>
                                This Franchise Agreement constitutes the entire agreement and understanding of the Parties with respect to the subject matter hereof and supersedes any and all prior negotiations, correspondence, agreements, understandings duties or obligations between the Parties with respect to the subject matter hereof.
                            </p>
                        )}

                        <p>
                            <b className='clause-count'></b> <b>NO OTHER RIGHTS GRANTED:</b>
                        </p>
                        <p>
                            A Nothing in this Agreement is intended to grant any rights under any patent, copyright or other intellectual property rights of any Party in favour of the other, nor shall this Agreement be construed to grant any Party any rights in or to the other Party's Confidential Information, except the limited right to use such Confidential Information in connection with the proposed relationship between the parties. The Receiving Party shall not receive any intellectual property rights in the Confidential Information other than a limited right to use the Confidential Information for the purposes specified in this Agreement. All intellectual property rights shall continue to vest with the Disclosing Party The Disclosing Party shall retain all title, interest and rights and all intellectual property and proprietary rights in the Confidential Information. No license under any trademark, patent or copyright, or application for same which are now or thereafter may be obtained by Disclosing Party is either granted or implied by the conveying of Confidential Information. The Receiving Party shall not conceal, alter, obliterate, mutilate, deface or otherwise interfere with any

                            trademark, trademark notice, copyright notice, confidentiality notice or any notice of any other proprietary right of

                            the Disclosing Party on any copy of the Confidential Information, and shall reproduce any such mark or notice on all

                            copies of such Confidential Information. Likewise, the Receiving Party shall not add or emboss its own or any other

                            mark, symbol or logo on such Confidential Information.
                        </p>
                        <p>
                            <b className='clause-count'></b> <b>AMENDMENTS:</b>
                        </p>
                        <p>
                            Any change, alteration, amendment, or modification to this Franchise Agreement must be in writing and signed by authorized representatives of both Parties.

                        </p>
                        <p id="courts">
                            <b className='clause-count'></b> <b>DISPUTE RESOLUTION:</b>
                        </p>
                        <p>
                            Any dispute(s) arising out of this Agreement shall, as far as possible, be settled amicably between the Parties hereto failing which the following shall apply:
                        </p>

                        {isCheckedMechanism === false && (
                            <p>
                                The courts in <span className='input-content'>{courts}</span> Agreement shall have exclusive jurisdiction over any dispute, differences or claims arising out of this agreement.
                            </p>
                        )}
                        {isCheckedMechanism === true && (
                            <>
                                <p>(a) Any dispute under this Agreement shall be referred to arbitration by a sole arbitrator to be appointed jointly by the Parties.</p>
                                <p>(b) The arbitration proceedings shall be held in <span className='input-content'>{arbitration}</span> in accordance with the provisions of the Arbitration and Conciliation Act, 1996 or any statutory re-enactment or modification thereof for the time being in force.
                                </p>
                                <p>
                                    (c) The Parties agree that the arbitration award shall be final and may be enforced as a decree.
                                </p>
                                <p>
                                    (d) The Parties further agree that subject to the above only the
                                    competent courts at <span className='input-content'>{courts}</span>shall have jurisdiction in all matters arising hereunder

                                </p>
                                <p>
                                    (e) The Parties further agree to keep the arbitration proceedings and the arbitral award confidential.

                                </p>
                            </>
                        )}
                        {lawyers === 'yes' && (
                            <p>
                                If either Party employs attorneys to enforce any rights arising out of or relating to this Agreement, the prevailing
                                Party shall be entitled to recover reasonable costs and attorneys' fees.
                            </p>
                        )}


                        <p><b className='clause-count'></b> <b>INDEPENDENT PARTIES:</b></p>
                        <p>
                            The Parties hereby acknowledge and agree that nothing in this Agreement shall be deemed to constitute a partnership, joint venture, agency relationship or otherwise between the Parties. The Franchisee shall be considered an independent contractor for the Franchisor. This Agreement is for the sole and express purpose of that independent contractor relationship for the opening and operation of the New Franchisee Business under the terms and conditions herein.

                        </p>
                        <p><b className='clause-count'></b> <b>EXCLUSIVITY:</b></p>
                        <p>
                            Nothing in this Agreement restricts the Disclosing Party or its group companies from discussing similar arrangements and/or any related transaction with any other party, any regulatory body in India and their respective successors.

                        </p>
                        <p><b className='clause-count'></b> <b>ASSIGNMENT:</b></p>
                        {assign === 'no' && (
                            <p>
                                This Agreement shall not be assignable by any Party without the prior written consent of the other Party
                            </p>
                        )}
                        {assign === 'yes' && (
                            <p>
                                Franchisor shall have the right to assign or transfer any of its rights or delegate any of its obligations under this Agreement in whole or in part to any person, firm or corporation. As to Franchisee, however, this Agreement, or the rights granted hereunder, may not be assigned, sold, leased or otherwise transferred in whole or part by Franchisee, without prior express written consent of Franchisor, which consent must not be unreasonably withheld if the proposed transferee meets Franchisor's then-existing requirements for franchisees. If Franchisor agrees to any assignment, Franchisor and Franchisee shall then discuss the terms of the assignment, which shall be through an additional executed agreement. If Franchisee attempts to assign or otherwise transfer this Agreement without Franchisor's approval, Franchisor may terminate the Agreement in Franchisor's sole and exclusive discretion.
                            </p>
                        )}

                        <p>Franchisor: </p>
                        <p><span className='input-content'> {name}</span> </p>
                        <p>Email:   <span id='email' className='input-content'> {email}</span> </p>
                        <p>
                            Franchisee:
                        </p>
                        <p>
                            {answer === 'yes' && (
                                <p>{address}</p>
                            )}
                            {fullAddress}
                        </p>
                        <p>
                            Email:<span className='input-content'>{email_of_Franchisee}</span>
                        </p>
                        <p>
                            Notices sent as above shall be deemed to have been received 3 working days after the day of posting (in the case of inland first class mail), or 7 working days after the date of posting (in the case of air mail), or next working day after sending (in the case of e-mail).
                        </p>
                        <p>
                            In proving the giving of notice, it shall be sufficient to prove that the notice was left, or that the envelope containing the notice was properly addressed and posted, or that the applicable means of telecommunication was addressed and dispatched and dispatch of the transmission was confirmed and/or acknowledged as the case may be.

                        </p>
                        {isSolicitation === true && (
                            <>
                                <p>
                                    <b className='clause-count'></b> <b>NON SOLICITATION:</b>
                                </p>
                                <p>
                                    A Party shall not for a period of<span className='input-content'>{client}</span> from the Effective Date solicit the clients or customers of the other Party ("Existing Party') to supply goods or services to them of the same or similar type as provided by the Existing Party. Without limitation to the above, the Franchisee shall not:
                                </p>
                                <p>
                                    (a) Solicit the clients or customers of Franchisor to provide services or supply goods to them of the same or a similar type to those provided by Franchisor during the course of this Agreement and for a period of
                                    <span className='input-content'>{client}</span> following the termination of this Agreement;
                                </p>
                                <p>
                                    (b) Endeavour to entice away from Franchisor or employ or offer to employ any person who is employed by Franchisor during the term of this Agreement and for
                                    <span className='input-content'>{client}</span>following the termination of this Agreement, whether or not the person would commit a breach of his or her contract of employment by being enticed or accepting employment with them.
                                </p>
                            </>
                        )}
                        <p>
                            <b className='clause-count'> </b> <b>GOVERNING LAW:</b>
                        </p>
                        <p>
                            This Agreement and all issues arising out of the same shall be construed in accordance with the laws of India.

                        </p>
                        <p>
                            <b>IN WITNESS WHEREOF,</b> the Parties hereto have executed this Agreement:
                        </p>
                        <p>
                            <span className='input-content-sign'> </span>
                        </p>
                        <p>{name}</p>
                        <p> By: <span id="signName" className='input-content'> {signName}</span> </p>
                        <p><span id="designation" className='input-content'> {designation}</span></p>

                        <p>
                            <span className='input-content-sign'> </span>
                        </p>
                        <p>{name}</p>
                        <p> By: <span className='input-content'> {signName_of_Franchisee}</span> </p>
                        <p><span className='input-content'>{designation_of_Franchisee}</span></p>

                    </div>
                    <div className='col-1 bg-light'></div>
                    {/* <button className='col-2 btn btn-primary mb-5' onClick={generatePDF}>Generate PDF</button> */}
                </div>
            </div>

        </>
    );
};

export default FormPDFGenerator;
